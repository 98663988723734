<template>
  
    <banner :banner="banner" :caption="caption" :path="bannerPath"></banner>

    <div class="container-fluid mt-3 border-bottom-red">
        <div class="row">
            <div class="col-2">
                <div class="content-block">
                    <img class="icon-red img-left" src="../../assets/images/sekilas-tentang-w.svg" alt="icon">
                </div>
            </div>

            <div class="col-10">
                <div class="content-block mb-3">
                    <h3> <span class="text-primary">EPROCUREMENT</span> <span class="text-danger">INFORMATION</span></h3>

                    <div class="border-bottom-black">
                        <div class="d-flex justify-content-end mb-3">
                            <select v-model="shorting" class="form-select form-select-sm right-width" aria-label=".form-select-sm example">
                                <option selected disabled>Shorting from</option>
                                <option value="1">Newest</option>
                                <option value="0">Oldest</option>
                                <option value="-1">Random</option>
                            </select>
                        </div>
                    </div>

                    <div v-if="shorting > 0">
                        <template v-for="data in dataTerbaru" :key="data.kode_eproc">
                            <div class="row mt-3 border-bottom-blue">
                                <div class="col-2">
                                    <div class="content-block">
                                        <img class="icon-red img-left" src="../../assets/images/sekilas-tentang-w.svg" alt="icon">
                                    </div>
                                </div>

                                <div class="col-8">
                                    <h6 class="text-title"> {{ data.nama_pengadaan }} </h6>
                                    <p class="text-time"> <timeago :datetime="data.tgl_upload" /> </p>
                                    <p v-html="data.pengumuman.substr(0, 150)" class="text-p"></p>
                                </div>
                                <div class="text-end">
                                    <a>
                                        <router-link :to="'/eprocurement/read/' + data.kode_eproc">
                                            read more <i class="zmdi zmdi-caret-right-circle"></i>
                                        </router-link>
                                    </a>
                                </div>
                            </div>
                        </template>
                    </div>
                    
                    <div v-else-if="shorting === 0">
                        <template v-for="data in dataTerlama" :key="data.kode_eproc">
                            <div class="row mt-3 border-bottom-blue">
                                <div class="col-2">
                                    <div class="content-block">
                                        <img class="icon-red img-left" src="../../assets/images/sekilas-tentang-w.svg" alt="icon">
                                    </div>
                                </div>

                                <div class="col-8">
                                    <h6 class="text-title"> {{ data.nama_pengadaan }} </h6>
                                    <p class="text-time"> <timeago :datetime="data.tgl_upload" /> </p>
                                    <p v-html="data.pengumuman.substr(0, 150)" class="text-p"></p>
                                </div>
                                <div class="text-end">
                                    <a>
                                        <router-link :to="'/eprocurement/read/' + data.kode_eproc">
                                            read more <i class="zmdi zmdi-caret-right-circle"></i>
                                        </router-link>
                                    </a>
                                </div>
                            </div>
                        </template>
                    </div>

                    <div v-else>
                        <template v-for="data in dataRandom" :key="data.kode_eproc">
                            <div class="row mt-3 border-bottom-blue">
                                <div class="col-2">
                                    <div class="content-block">
                                        <img class="icon-red img-left" src="../../assets/images/sekilas-tentang-w.svg" alt="icon">
                                    </div>
                                </div>

                                <div class="col-8">
                                    <h6 class="text-title"> {{ data.nama_pengadaan }} </h6>
                                    <p class="text-time"> <timeago :datetime="data.tgl_upload" /> </p>
                                    <p v-html="data.pengumuman.substr(0, 150)" class="text-p"></p>
                                </div>
                                <div class="text-end">
                                    <a>
                                        <router-link :to="'/eprocurement/read/' + data.kode_eproc">
                                            read more <i class="zmdi zmdi-caret-right-circle"></i>
                                        </router-link>
                                    </a>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import Banner from '../banner/banner.vue'
import axios from 'axios'

export default {
    name: 'pageEprocurement',
    components: {
        Banner,
    },
    data () {
        return {
            // bannerPath: 'http://localhost:8080/assets/banner/',
            bannerPath: 'https://api.plnbag.co.id/assets/banner/',
            banner: '',
            caption: '',
            shorting: 'Shorting from',
            dataTerbaru: [],
            dataTerlama: [],
            dataRandom: []
        }
    },
    mounted ()  {
        this.loadBanner()
        this.terbaru()
        this.terlama()
        this.acak()
    },
    methods: {
        loadBanner () {
            axios.get('eproc_banner')
                .then((res) => {
                    this.banner = res.data.data.banner
                    this.caption = res.data.data.caption_en
                })
        },

        terbaru () {
            axios.get('eproc_terbaru')
                .then((res) => {
                    this.dataTerbaru = res.data.data
                })
        },

        terlama () {
            axios.get('eproc_terlama')
                .then((res) => {
                    this.dataTerlama = res.data.data
                })
        },

        acak () {
            axios.get('eproc_acak')
                .then((res) => {
                    this.dataRandom = res.data.data
                })
        }
    }
}
</script>

<style scoped>
    .img-left {
        width: 85px;
        height: auto;
    }

    @media screen and (max-width: 767px) {
        .img-left {
            width: 45px;
            height: auto;
        }
    }

    .icon-red {
        background-color: #ea2e23;
        border-radius: 5px;
        padding: 10px;
        float: right;
    }

    @media (max-width: 767px) {
        .icon-red {
            float: none;
        }
    }

    /* .content-home .content-block {
        padding: 25px 0 0;
    }

    .content-home .content-block h3 {
        color: #0158a7;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block span.title-w {
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        margin-left: 10px;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block p.text-w {
        color: #fff;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .content-block p {
        color: #404041;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    } */

    .border-bottom-red {
        border-bottom: 5px solid #ea2e23;
    }

    .border-bottom-blue {
        border-bottom: 2px solid #0158a7;
    }

    .border-bottom-black {
        border-bottom: 2px solid #000000;
    }

    .right-width {
        width: 160px;
    }

    .text-title {
        color: #0158a7;
        font-size: 20px;
        font-weight: 500;
        text-transform: uppercase;
    }

    .text-time {
        color: #ea2e23;
        font-size: 11px;
        font-weight: 500;
    }

    @media screen and (max-width: 767px) {
        .text-title {
            font-size: 14px;
            font-weight: 200;
        }
    }

    p.text-p {
        font-size: 12px;
    }

    .text-end a {
        text-decoration: none;
        color: #ea2e23;
    }

    @media screen and (max-width: 767px) {
        .text-end a {
            font-size: 11px;
        }
    }

</style>